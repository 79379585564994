import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Application of RS and GIS in Civil Engineering`}</h1>
    <p>{`Lectures:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-1-Application-of-RS-GIS-in-civil-engineering-introduction.pdf"
      }}>{`lecture-1 Application of RS & GIS in civil engineering introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-2-Interaction-with-atmosphere.pdf"
      }}>{`lecture-2 Interaction with atmosphere`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-3-Spectral-response-pattern-signature-reflectance.pdf"
      }}>{`lecture-3 Spectral response pattern signature reflectance`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-4-5-Plateforms.pdf"
      }}>{`lecture-4-5 Plateforms`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-6-Image-processing.pdf"
      }}>{`lecture-6 Image processing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-7-Image-processing.pdf"
      }}>{`lecture-7 Image processing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-8a-Introduction-of-Spectroradiometer-and-Determination-of-Spectral-Reflectance-of-different-ground-features.pdf"
      }}>{`lecture-8a Introduction of Spectroradiometer and Determination of Spectral Reflectance of different ground features`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-8b-Handheld-2-Spectroradiometer.pdf"
      }}>{`lecture-8b Handheld 2 Spectroradiometer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-9-Geographic-information-system.pdf"
      }}>{`lecture-9-Geographic information system`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-10-GIS-Data-management.pdf"
      }}>{`lecture-10-GIS Data management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/lecture-11-GIS-Data-manipulation-and-analysis.pdf"
      }}>{`lecture-11-GIS Data manipulation and analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Lecture-12-Getting-started-with-ARCGIS_Watershed-stream-network.pdf"
      }}>{`Lecture-12 Getting started with ARCGIS_Watershed-stream network`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Lecture-13-Map-projection-systems.pdf"
      }}>{`Lecture-13 Map projection systems`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      